
.iconHover{
    width: 30px;
    height: 30px;
}

.iconHover:hover{
    background-color: rgba(0, 128, 0, 0.493);
    cursor: pointer;
    border-radius: 10px;
}

