

/*=====================================================
Dashboard styles coded by Xtreme Software Solutions llc
------------------MAP & STEP1  styles------------------
=======================================================*/
#map 
{
height: 100%;
}

#infowindow-content 
{
display: none;
}

#map #infowindow-content 
{
display: inline;
}

.step1
{
margin-top: 30px;
}


.googleimage
{
height: 147px;
}




.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
    
  .pricing hr {
    margin: 1.5rem 0;
  }
  
  .pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
  }
  
  .pricing .card-price {
    font-size: 3rem;
    margin: 0;
  }
  
  .pricing .card-price .period {
    font-size: 0.8rem;
  }
  
  .pricing ul li {
    margin-bottom: 1rem;
  }
  
  .pricing .text-muted {
    opacity: 0.7;
  }
  
  /* Hover Effects on Card */
  @media (min-width: 992px) {
    .pricing .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
    .pricing .card:hover .btn {
      opacity: 1;
    }
  }
  