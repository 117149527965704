/**
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 * Xtreme Software Solutions LLC @2020
 * Coded By @jerryurenaa
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 */

.emojiselector
{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr ));
  grid-gap: 0.7rem;
}

.emojiselector img:hover 
{
  background-color: #23468c;
  border-radius: 5px;
}

.emojiBackground{
  background-color: #23468c;
  border-radius: 5px;
}

.iphonexm{

    background-image: url("../images/IPhone_X.png");
    min-height: 800px;
    width: 450px;
    padding-top: 87px;
    background-size: 410px 720px;
    padding-left: 44px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 44px;
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    
    }
    
    .iphoneheaderimg{
    height: 170px !important;
    width: 100%;
    }
    
    /*end of experiencia emoji*/
    
    .imagecontainer{
        position: relative;
      text-align: center;
      color: black;
      height: 121px;
      
    }
    
    
    /* Centered text */
    .imagencentered {
     position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 14.5% 5% 14.5%;
        color: white;
        width: 100%;
    }


    .help-rv {
        background-color: #6462ff;
        color: white;
        padding-top: 15px;
        padding-bottom: 5px;
        padding-left: 3px;
        padding-right: 3px;
      }

      .customPointer{
        cursor: pointer;
      }
  

      .customPointer:hover{
      }