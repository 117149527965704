

.experienceHeader{
    height: 170px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-bottom: -50px;
    z-index: 1;
}

.experienceHeaderText{
    color: #ffffff;
    text-align: center;
    
}

.experienceSubHeader{
    color: #333;
    padding: 17px;
    padding-bottom: 0px;
    margin-bottom: 5px;
    padding-top: 5px;
    
}

.experienceReviewsLogo{
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
}

.reviewsimgsContainer{
    width: 200px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin: auto;
}

.publicInfo {
    background: url(../images/gmb.svg) no-repeat;
    background-size: 20px;
    background-position-y: 10px;
    background-position-x: 98%;
    padding-bottom: 0px;
    background-color: white;
    padding-top: 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    z-index: 2;
}

.publicInfo-tab2 {
    /*background: url(../images/gmb.svg) no-repeat;*/
    background-color: #fff;
    padding-top: 1px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.publicInfo-tab3 {
    /*background: url(../images/gmb.svg) no-repeat;*/
    background-color: #fff;
    padding-top: 1px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.descargaPdf {
    font-size: 15px;
    padding-bottom: 8px;
}

.descargaPdf a {
    padding: 5px 15px 7px 15px;
    background: #f0b200;
    color: white;
    box-shadow: 2px 2.4px #dec682c7;
    border-radius: 25px;

}

/*.grecaptcha-badge {
    display: none !important;
}*/

.experienceSubHeader p {
    margin-bottom: 5px;
}

.experienceSubHeader + hr {
    background: lightgray;
    width: 15%;
}

.publicBN {
    color: #333;
    font-weight: 700;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
    width: 80%;
    text-align: left;
    padding-left: 20px;
    font-size: 6vw !important;
    padding-top: 5px;
}

.publicStars > .Stars {
    font-size: 1.3rem;
    line-height: 1.5;
}

.publicRating {
    font-size: 1.2rem;
    font-weight: 400;
    padding-right: 5px;
}
.emojiClic h2 {
    color: #333;
}

.publicRS {
    padding-left: 20px;
    float: left;
}


.publicTR {
    line-height: 2;
    display: flex;
    color: #0000bd;
    padding-left: 5px;
}

/*************** Modal public recomendation *****************/

/*.containerModal .modal-content {
    background: linear-gradient( 
181deg
 ,#ffffff 20%,#bdbdbd  100%);
}
*/

.iconM {
    margin-right: 5px !important;
    padding: 12px;
    margin-left: auto!important;
    display: flex;
    margin-top: 5px;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 15px;
    background: #ffffff;
}

.recoment {
    border-radius: 15px;
    border: 1.5px solid #e6e6e6;
    margin-bottom: 15px;
    margin-left: 10px;
    background: #ffffffb0;
    margin-right: 15px;
    box-shadow: #b7b7b7 0px 2px 5px;
}

.modalEt {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.contM {
    padding: 15px;
}

.contM h3:first-letter {
    text-transform: uppercase;
}

.copyFooter {
    font-size: 13px;
}


/*Mobile menu*/

.mobileMenuWrap{
    position: fixed;
    background-color: #ffffff;
    height: 60px;
    width: 100%;
    padding-top: 10px;
    padding-right: 40px;
    border-top: 3px solid #dedede61;
    display: flex;
    justify-content: center;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    flex-direction: column;
    bottom: 0!important;
}

.mobileMenu{
    height: 10px;
    display:  flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-between;
    
    
}

.mobileMenuItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #868686;

}

.mobileMenuItem svg{
    align-self: center;
    margin: auto;

}

.mobileMenuItem a {
    display: grid;

}

li.mobileMenuItem a:hover {
    color: #f0b200 !important;
}

li.mobileMenuItem:hover {
    color: #f0b200 !important;
}

.mobileMenuItem span{
    font-weight: 600;
    font-size: 13px;

}


.grecaptcha-badge { 
    visibility: hidden;
}

/*wrapper*/
.experienceContent{
    z-index: 0;
    position: relative;
    margin-bottom: 80px;
    overflow: auto;
}

.contentWrapper{
    overflow: auto;
    width: 100%;
}

.fullHeight{
    width: 100%;
    position: fixed;
    min-height: 100vh;
    padding-bottom: 100px;
}

html {
    scroll-behavior: smooth;
}

.cartaScroll {
display: contents;
color:#868686;
}

.iframePhone {
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}
@media only screen and (max-width: 680px) {
.phonePreview {
    display: none;
}
}
    .iframePhone .publicTR {
    font-size: 13px !important;
    }