@charset "UTF-8";
:root {
  --star-color: #674507;
  --star-background: #ffa500;

}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  font-family: Times;
  line-height: 1;
  position: relative;
  font-size: var(--ratingSise);
  max-width:  15rem;
}

.Stars::before {
  content: '★★★★★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
