/*#888888888888888888888888888888888888888888888
#---->Xtreme Software Solutions LLC 2020<----#
#888888888888888888888888888888888888888888888*/

.loginbg{
    background-image: url(../../resources/images/loginbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position:fixed;
    overflow-y:auto;
    overflow-x:hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.loginpage{
    border-radius: 1rem;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    padding: 2rem;
    background: #2d2d2d69;
    
}

h1 {
  font-size: 2rem !important;
}

h2 {
  font-size: 1.5rem !important;
}

h3 {
  font-size: 1.25rem !important;
}

h4 {
  font-size: 1rem !important;
}


/*========>wrapper<========*/
#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}

#wrapper #content-wrapper #content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
/*========>End of Wrapper<========*/

/*========>Side Menu<========*/
.side-menu {
  width: 17rem;
  background-color: #444444;
  
}


.side-menu .nav-item .nav-link {
  height: 4rem;
  margin-left: 1rem;
}

.side-menu .nav-item .nav-link svg {
  font-size: 1.5rem;
  width: 2rem;
  margin-right: 0.5rem;
}

.side-menu .nav-item .nav-link span {
  font-size: 0.9rem;
}

.side-menu .nav-item .nav-link:hover {
  color: orange;
  text-decoration: none;
}

 .nav-item .nav-link:active {
  color: #858796;
}

.side-menu .logo-brand {
  height: 3.5rem;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}











/*========>END of Side Menu<========*/
  
/*========>side Menu toggle<========*/
@media (min-width: 576px) 
{
  .side-menu-toggled {
    width: 5rem !important;
    background-color: #444444;
  }

 
  .side-menu-toggled .nav-item .nav-link{
    width: 5rem !important;
    margin-bottom: 1rem;
    padding: 0.75rem;
    
  }

  .side-menu-toggled .logo-brand span, .mobileicon {
    display: none;
  }

  .side-menu-toggled .nav-item .nav-link svg {
    font-size: 1.7rem;

  }

  .side-menu-toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }

  .side-menu-toggled .nav-item .nav-link:hover {
    color: orange;
    text-decoration: none;
  }

  .side-menu-toggled .nav-link:active {
    color: #858796;
  }

  .toggle-side-menu-button{
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .side-menu-toggled .logo-brand {
    height: 3rem;
  }



}
/*========>END of side Menu toggle<========*/

/*========>Mobile Container<========*/
@media (max-width: 575px) 
{
  .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-right: 0px !important;
    padding-left: 0px !important;
   
  }
}



/*========>END mobile container<========*/

/*========>Mobile menu<========*/
@media only screen and  (max-width: 575px) 
{
  /*Show only first 3-4 items*/
  .side-menu-toggled .nav-item:nth-child(2n+3) {
    display: none;
  }

  /*More styles*/
  .side-menu-toggled .mobileicon {
    position: absolute;
    right: 0;
    width: 3.5rem;
    height: 4rem;
  }

 

  .side-menu-toggled{
    background-color: #333;
    width: 100%;
    height: 70px;
    bottom: 0!important;
    overflow: visible;
    position: fixed!important;
    display: flex!important;
    flex-direction: row!important;
    color: white;
    z-index: 999;
    
  }

  .side-menu-toggled .nav-item {
    margin-top: 5px;
    margin-left: 1.2rem;
  }

  .side-menu-toggled .nav-item .nav-link{
    text-align: center;
    padding-left: 0;
  }

  .side-menu-toggled .logo-brand, .logo-brand span, .toggle-side-menu-button {
    display: none;
  }

  .side-menu-toggled .nav-item .nav-link svg, .mobileicon svg {
    font-size: 1.7rem;
  }

  .side-menu-toggled .nav-item .nav-link span, .mobileicon span {
    font-size: 0.65rem;
    display: block;
  }

  .side-menu-toggled .nav-item .nav-link:hover, .mobileicon a:hover{
    color: orange;
    text-decoration: none;
  }

  .side-menu-toggled .nav-link:active, .mobileicon a:active {
    color: #858796;
  } 

  /*========>End of Mobile menu<========*/

  /*<========>More mobile menu show<========*/
  .more-mobile-show {
    bottom: 0!important;
    position: fixed!important;
    padding-left: 10px !important;
    background-color: #333;
    color: white;
    display: inline-block;
    width: 100%;
    z-index: 999;
  }

  .more-mobile-show .logo-brand{
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    margin-right: 0;
    margin-bottom: 10px;
    width: 250px;
  }

  .more-mobile-show .logo-brand img{
    width: 15rem;
  }

  .more-mobile-show .nav{
    display: block;
  }

  .more-mobile-show  .mobileicon {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 1rem;
  }

  .more-mobile-show .nav-item .nav-link{
    padding-left: 0;
  }

  .more-mobile-show .nav-item .nav-link svg{
    width: 20px;
  }

  .more-mobile-show .nav-item .nav-link span {
    font-size: 0.9rem;
    margin-left: 0.8rem;
  }

  .more-mobile-show .nav-item .nav-link:hover{
    color: orange;
    text-decoration: none;
  }

  .more-mobile-show .nav-link:active{
    color: #858796;
  }
 
}
/*========>END of More mobile menu show<========*/





/*Copyright message */
.copyright{
  text-align: center;
  margin-top: 20px;
  height: 30px;
  padding-top: 5px;
  background: #ffffff;
}


/*Scroll to top */
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
  z-index: 998;
}

@media screen and (max-width: 576px) 
{
  .scroll-to-top {
    bottom: 5rem;
  }
}



/*Stepper */
.steps {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  overflow-x: auto;
}

.steps .step:first-child {
  margin-left: auto;
}

.steps .step:last-child {
  margin-right: auto;
}

.step:first-of-type .step-circle::before {
  display: none;
}

.step:last-of-type .step-content {
  padding-right: 0;
}

.step-content {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}

.step-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: #adb5bd;
  border: 2px solid #adb5bd;
  border-radius: 100%;
  background-color: #fff;
  font-weight: bold;
}

.step-circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: calc(5rem + 1rem - 1.5rem);
  height: 2px;
  transform: translate(-100%, -50%);
  color: #adb5bd;
  background-color: currentColor;
}

.step-text {
  color: #adb5bd;
  word-break: break-all;
  margin-top: 0.25em;
  font-weight: bold;
}

.step-active .step-circle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.step-active .step-circle::before {
  color: #007bff;
}

.step-active .step-text {
  color: #007bff;
}

.step-error .step-circle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.step-error .step-circle::before {
  color: #dc3545;
}

.step-error .step-text {
  color: #dc3545;
}

.step-success .step-circle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.step-success .step-circle::before {
  color: #28a745;
}

.step-success .step-text {
  color: #28a745;
}



/*First time user view styles */

.stepContentContainer{
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

/************ created by Enrique ************/

.titleBg {
  background-color: white !important;
  border-bottom: none !important;
}

div.totalReviews {
  margin: 30px 0px 35px 0px !important;
}

div.ratingGMB {
margin: 30px 5px 35px 0px !important;
}

.ratingCS {
  margin: 50px 0px 50px 0px;
}
/********************************************/

/***** mobile optimization by Enrique ******/

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 1.8rem !important;
    text-align: center !important;
  }
  h2 {
    font-size: 1.3rem !important;
  }

  h4 {
    font-size: 1.1rem !important;
  }

  div.headerSpace {
    margin-top: 0px !important;
  }

  div.mobileIphone {
    background-image: url(/static/media/IPhone_X.2a964acb.png);
    min-height: 700px;
    max-width: 400px;
    width: auto;
    padding-top: 53px;
    padding-left: 22px;
    margin-top: 0px !important;
    margin-left: auto;
    padding-right: 22px;
    background-position-y: 10px !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .help-rv + .container {
    margin-top: 15px !important;
  }

  div.neContainer {
    padding: 0 !important;
  }

  div.colNegativeE {
    flex: auto;
    margin-bottom: 3rem;
  }
  div.colRating {
    padding: 0px 25px 0px 25px;
  }

  div.ratingCS {
    display: block !important;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  div.ratingCS div {
    padding-right: 0 !important;
    margin-right: 0 !important;
    margin: auto;
    justify-content: center;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  div.reviewsNoResp {
    padding: 0 !important;
  }

  div.reviewsNoResp h2 {
    text-align: center;
  }

  div.reviewsResp {
    padding: 0 !important;
  }

  div.reviewsResp h2 {
    text-align: center;
  }

  div.ratingGMB {
    margin: 30px 5px 0px 0px !important;
  }

  div.totalReviews {
    margin: 10px 0px 0px 0px !important;
  }

  .tab-pane .experienceTabs {
    padding: 1rem !important;
  }

}


/********************************************/

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  
  div.colOpt {
    flex: 0 0 100%;
    max-width: 100%;
}

h1 {
  font-size: 1.7rem !important;
}

h2 {
  font-size: 1.2rem !important;
}

h4 {
  font-size: 1rem !important;
}

div.neContainer {
  padding: 0 !important;
}

div.colNegativeE {
  flex: auto;
  margin-bottom: 3rem;
}
div.colRating {
  padding: 0px 25px 0px 25px;
}

div.ratingCS {
  display: block !important;
  margin-top: 30px;
  margin-bottom: 50px;
}

div.ratingCS div {
  padding-right: 0 !important;
  margin-right: 0 !important;
  margin: auto;
  justify-content: center;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.reviewsNoResp {
  padding: 0 !important;
}

div.reviewsNoResp h2 {
  text-align: center;
}

div.reviewsResp {
  padding: 0 !important;
}

div.reviewsResp h2 {
  text-align: center;
}
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  div.Stars {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  div.ratingNumber {
    font-size: 1.5rem;
  }

}

/*************** Tab component by Enrique ***************/

.tab-list {  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

/*************** End Tab component by Enrique ***************/

span.iconCatalog {
  font-size: 25px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

