/**
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 * Xtreme Software Solutions LLC @2020
 * FrontEnd Global CSS V0.1
 * Coded By @jerryurenaa
 * We code so you can sleep better :D
 * XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 */


/*General*/
:root {
    --input-padding-x: .75rem;
    --input-padding-y: .75rem;
}

h1 {
  font-size: 5vh;  
  color: #5a5c69;     
}
h2 {
  font-size: 4vh;  
  color: #858796;      
  font-weight: 500;
}

h3 {
  font-size: 4vh;        
}


/*Mobile Responsive */
@media (max-width: 576px) 
{
  h1 {
    font-size: 2rem;     
    color: #5a5c69;  
  }
  h2 {
    font-size: 1.5rem;     
    color: #858796;   
    font-weight: 500;
  }
  
  h3 {
    font-size: 1rem;        
  }
  

}

.starColor{
  color: #36963645;
}


/*Button to link*/
.buttonToLink{
    background: none;
    border: none;
    color: #ffffff
}

.buttonToLink:hover{
    text-decoration: underline;
}

.buttonToLink:focus {
    outline: none;
}


/*Floating input label*/
.floatingLabel {
  position: relative;
  margin-bottom: 1rem;
}

.floatingLabel > input{
    padding: var(--input-padding-y) var(--input-padding-x);
    font-size: 1rem;
}

.floatingLabel > label {
  position: absolute;
  top: 0;
  margin-top: -5px;
  color: #495057;
  transition: all .1s ease-in-out;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.floatingLabel input::-webkit-input-placeholder {
  color: transparent;
}

.floatingLabel input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.floatingLabel input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 0.8rem;
  color: rgb(2, 2, 2);
}

/*Password input*/
.passwordInput{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0.75rem;
    margin-top: 7px;
}


/*Screen alerts*/
.globalAlert{
  position: absolute!important;
}

.topRightAlert{
    position: fixed !important;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    min-width: 25%;
    max-width: 50%;
}

.topLeftAlert{
    position: fixed !important;
    top: 1rem; 
    left: 1rem;
    z-index: 100;
    min-width: 25%;
    max-width: 50%;
}

.bottomLeftAlert{
    position: absolute!important;
    bottom: 1rem; 
    left: 1rem;
    z-index: 100;
    min-width: 25%;
    max-width: 50%;
    float: left;
}

.bottomRightAlert{
    position: absolute!important;
    bottom: 1rem; 
    right: 1rem;
    z-index: 100;
    min-width: 25%;
    max-width: 50%;
    float: left;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}


/*modal*/
.fullscreenModal{
    max-width: 1600px;
    width: 90%;
}


.passwordInputFeedback.is-invalid{
    background-image: none;
}



/*BTN styles*/
.btn-circle {
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn {
    height: 1.8rem;
    width: 1.8rem;
    font-size: 0.75rem;
  }
  
  .btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.35rem;
  }

  /*Toggle switch styles*/
  
 .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #17A2B9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #17A2B9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



/*border style*/
.border-2{
border-style:solid;
border-width: 2px;
}

.border-3{
border-style:solid;
border-width: 3px;
}

.border-4{
border-style:solid;
border-width: 4px;
}

.border-5{
border-style:solid;
border-width: 5px;
}

.border-6{
border-style:solid;
border-width: 6px;
}

.border-7{
border-style:solid;
border-width: 7px;
}

.border-8{
border-style:solid;
border-width: 8px;
}


.scrollEffect {
  width: 100%;
  max-height: 400px;
  overflow-x: auto;
  overflow-x: auto; 
}
 p {
  font-size: 0.9rem !important;
 }